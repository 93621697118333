define('sags-front-bo/components/maps/here-map-point', ['exports', 'sags-front-bo/config/environment', 'sags-front-bo/utils/map', 'lodash'], function (exports, _environment, _map, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var platform = new H.service.Platform({
    app_id: _environment.default.APP.here.id,
    app_code: _environment.default.APP.here.code,
    useCIT: _environment.default.APP.here.cit,
    useHTTPS: _environment.default.APP.here.https,
    apikey: _environment.default.APP.here.keyApi
  });

  // Layer Satellite
  var rasterTileServiceSat = platform.getRasterTileService({
    queryParams: {
      'style': 'explore.satellite.day'
    }
  });
  var rasterTileProviderSat = new H.service.rasterTile.Provider(rasterTileServiceSat);
  var rasterTileLayerSat = new H.map.layer.TileLayer(rasterTileProviderSat);
  // Layer MapView
  var rasterTileServiceMapview = platform.getRasterTileService({
    queryParams: {
      'style': 'lite.day'
    }
  });
  var rasterTileProviderMapview = new H.service.rasterTile.Provider(rasterTileServiceMapview);
  var rasterTileLayerMapview = new H.map.layer.TileLayer(rasterTileProviderMapview);

  exports.default = Ember.Component.extend({
    aggregatedSections: null,
    zonesColor: null,

    didInsertElement: function didInsertElement() {
      // Afficher la carte
      var map = new H.Map(this.$('#map')[0], rasterTileLayerMapview, {
        zoom: this.get('zoom'),
        center: {
          lng: this.get('longitude'),
          lat: this.get('latitude')
        }
      });

      // Afficher la position de l'utilisateur
      var userCoord = { lat: this.get('latitude'), lng: this.get('longitude') };
      var groupMap = new H.map.Group();
      var iconName = 'default_marker.png';

      groupMap.addObject(this.addMarker(userCoord, _environment.default.rootURL + 'assets/images/' + iconName));
      map.addObject(groupMap);

      // Gestion du zoom et interface par défaut
      new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

      // Gestion du menu
      var ui = new H.ui.UI(map);
      ui.addControl('custom_settings_control', new H.ui.MapSettingsControl({
        baseLayers: [{
          label: 'Normal Map',
          layer: rasterTileLayerMapview
        }, {
          label: 'Satellite',
          layer: rasterTileLayerSat
        }],
        layers: []
      }));

      var aggregatedSections = this.get('aggregatedSections');
      if (!_lodash.default.isNil(aggregatedSections)) {
        for (var indexAggSection = 0; indexAggSection < aggregatedSections.get('length'); indexAggSection++) {
          this.send('addPolygonToMap', ui, map, aggregatedSections.objectAt(indexAggSection), this);
        }
      }
      this.set('map', map);
    },


    /**
     * Créer un marker de map
     *
     * @param {Object} coord - Coordonnées du marker à créer
     * @param {Object} iconPath - Chemin de l'icône du marker
     * @return {Object} Marker créé
     */
    addMarker: function addMarker(coord, iconPath) {
      var car = new H.map.Icon(iconPath);
      var newMarker = new H.map.Marker(coord, { icon: car });

      return newMarker;
    },


    // Fonction pour récupérer les coordonnées du tronçon agrégé
    getCoordinatesAggregatedSection: function getCoordinatesAggregatedSection(aggregatedSection) {
      var polygonCoordinates = [];
      var coordinates = aggregatedSection.get('coordinates');
      for (var indexCoordinate = 0; indexCoordinate < coordinates.length; indexCoordinate++) {
        var coordinate = coordinates.objectAt(indexCoordinate);
        polygonCoordinates.push(coordinate[1]);
        polygonCoordinates.push(coordinate[0]);
        polygonCoordinates.push(0);
      }
      return polygonCoordinates;
    },


    actions: {
      // Fonction pour dessiner un polygone sur une carte
      addPolygonToMap: function addPolygonToMap(ui, map, aggregatedSection, that) {
        var polygonCoordinates = this.get('getCoordinatesAggregatedSection')(aggregatedSection);
        var lineString = new H.geo.LineString(polygonCoordinates);

        // le setting est au format RGBA (#OpRRGGBB), on vire le canal alpha
        var fillColor = '#bf99ee';

        var polygon = new H.map.Polygon(lineString, {
          style: {
            fillColor: fillColor,
            strokeColor: '#000',
            lineWidth: 2
          }
        });
        var sector = aggregatedSection.get('tariffZone') + ' - ' + aggregatedSection.get('residentialSector');
        if (sector) {
          var _mapUtils$centerAggre = _map.default.centerAggregatedSection(polygonCoordinates),
              _mapUtils$centerAggre2 = _slicedToArray(_mapUtils$centerAggre, 2),
              lat = _mapUtils$centerAggre2[0],
              lon = _mapUtils$centerAggre2[1];

          var marker = _map.default.textSVG(sector, lat, lon);
          map.addObject(marker);
        }
        map.addObject(polygon);
      },


      // Fonction pour ajouter les zones sous forme de couleur
      addSectionColor: function addSectionColor(ui, map, aggregatedSection, that) {
        var polygonCoordinates = this.get('getCoordinatesAggregatedSection')(aggregatedSection);
        var lineString = new H.geo.LineString(polygonCoordinates);

        var sectionColor = '#C58DF4';
        if (aggregatedSection.get('parkingType') === 'STD') {
          sectionColor = that.get('zonesColor').filter(function (zone) {
            return aggregatedSection.get('tariffZone') === zone.key;
          })[0].value;
        }

        var polygon = new H.map.Polygon(lineString, {
          style: {
            fillColor: sectionColor,
            strokeColor: '#000',
            lineWidth: 2
          }
        });
        map.addObject(polygon);
      }
    }
  });
});