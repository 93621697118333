define('sags-front-bo/components/maps/here-map', ['exports', 'sags-front-bo/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var platform = new H.service.Platform({
    app_id: _environment.default.APP.here.id,
    app_code: _environment.default.APP.here.code,
    useCIT: _environment.default.APP.here.cit,
    useHTTPS: _environment.default.APP.here.https,
    apikey: _environment.default.APP.here.keyApi
  });

  // Layer Satellite
  var rasterTileServiceSat = platform.getRasterTileService({
    queryParams: {
      'style': 'explore.satellite.day'
    }
  });
  var rasterTileProviderSat = new H.service.rasterTile.Provider(rasterTileServiceSat);
  var rasterTileLayerSat = new H.map.layer.TileLayer(rasterTileProviderSat);
  // Layer MapView
  var rasterTileServiceMapview = platform.getRasterTileService({
    queryParams: {
      'style': 'lite.day'
    }
  });
  var rasterTileProviderMapview = new H.service.rasterTile.Provider(rasterTileServiceMapview);
  var rasterTileLayerMapview = new H.map.layer.TileLayer(rasterTileProviderMapview);

  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      // Afficher la carte
      var map = new H.Map(this.$('#map')[0], rasterTileLayerMapview, {
        zoom: 10,
        center: {
          lng: 4.835659,
          lat: 45.764043
        }
      });

      // TODO : Décommenter une fois que NokiaHere reveut de nous
      /*
      // Afficher une route statique initiale
      let router = platform.getRoutingService();
      let routeRequestParams = {
        mode: 'fastest;pedestrian',
        representation: 'display',
        routeattributes: 'waypoints,summary,shape,legs',
        maneuverattributes: 'direction,action',
        waypoint0: '45.7561752,4.896097499999996', // worldline
        waypoint1: '45.771944,4.89017089999993' // villeurbanne
      };
       router.calculateRoute(routeRequestParams, result => {
        const route = result.response.route[0];
         this.showRouteToMap(route);
        this.showIntersectionsToMap(route);
      }, () => {
        // TODO : Afficher notification quand composant popup prêt
      });*/

      // Afficher un user statique
      var userCoord = { lat: 45.7561752, lng: 4.896097499999996 };
      var groupMap = new H.map.Group();

      groupMap.addObject(this.addMarker(userCoord, _environment.default.rootURL + 'assets/images/agent_marker.png'));
      map.addObject(groupMap);

      // Gestion du zoom et interface par défaut
      new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

      // Gestion du menu
      var ui = new H.ui.UI(map);
      ui.addControl('custom_settings_control', new H.ui.MapSettingsControl({
        baseLayers: [{
          label: 'Normal Map',
          layer: rasterTileLayerMapview
        }, {
          label: 'Satellite',
          layer: rasterTileLayerSat
        }],
        layers: []
      }));

      this.set('map', map);
    },


    /**
     * Afficher un itinéraire sur la carte
     *
     * @param {Object} route - Itinéraire
     */
    showRouteToMap: function showRouteToMap(route) {
      var strip = new H.geo.Strip();
      var routeShape = route.shape;
      var polyline = void 0;

      routeShape.forEach(function (point) {
        var parts = point.split(',');
        strip.pushLatLngAlt(parts[0], parts[1]);
      });

      polyline = new H.map.Polyline(strip, {
        style: {
          lineWidth: 4,
          strokeColor: 'rgba(0, 128, 255, 0.7)'
        }
      });

      var map = this.get('map');

      map.addObject(polyline);
      map.setViewBounds(polyline.getBounds(), true);
    },


    /**
     * Afficher les intersections durant l'itinéraire sous forme de cercles
     *
     * @param {Object} route - Itinéraire
     */
    showIntersectionsToMap: function showIntersectionsToMap(route) {
      var svgMarkup = '<svg width="18" height="18" ' + 'xmlns="http://www.w3.org/2000/svg">' + '<circle cx="8" cy="8" r="8" ' + 'fill="#1b468d" stroke="white" stroke-width="1"  />' + '</svg>';
      var dotIcon = new H.map.Icon(svgMarkup, { anchor: { x: 8, y: 8 } });
      var group = new H.map.Group();

      // Afficher un marker à l'intersection
      route.leg.forEach(function (leg) {
        leg.maneuver.forEach(function (maneuver) {
          var marker = new H.map.Marker({
            lat: maneuver.position.latitude,
            lng: maneuver.position.longitude
          }, {
            icon: dotIcon
          });

          marker.instruction = maneuver.instruction;
          group.addObject(marker);
        });
      });

      var map = this.get('map');

      group.addEventListener('tap', function (evt) {
        map.setCenter(evt.target.getPosition());
      }, false);

      map.addObject(group);
    },


    /**
     * Créer un marker de map
     *
     * @param {Object} coord - Coordonnées du marker à créer
     * @param {Object} iconPath - Chemin de l'icône du marker
     * @return {Object} Marker créé
     */
    addMarker: function addMarker(coord, iconPath) {
      var car = new H.map.Icon(iconPath);
      var newMarker = new H.map.Marker(coord, { icon: car });

      return newMarker;
    }
  });
});